<template>
  <svg
    id="lorry_icon_svg"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 815 500"
    xml:space="preserve"
    class="h-12 w-18"
    style="enable-background: new 0 0 815 500"
  >
    <g>
      <g>
        <path
          d="M432.27,187.42H116.35c-2.87,0-5.19-2.32-5.19-5.19c0-2.87,2.32-5.19,5.19-5.19h315.92c2.87,0,5.19,2.32,5.19,5.19
			C437.46,185.1,435.14,187.42,432.27,187.42z"
        />
      </g>
      <g>
        <path
          d="M432.27,126.24H228.92c-2.87,0-5.19-2.32-5.19-5.19s2.32-5.19,5.19-5.19h203.35c2.87,0,5.19,2.32,5.19,5.19
			S435.14,126.24,432.27,126.24z"
        />
      </g>
      <g>
        <path
          d="M456,286.42H151.62c-2.87,0-5.19-2.32-5.19-5.19c0-2.87,2.32-5.19,5.19-5.19H456c2.87,0,5.19,2.32,5.19,5.19
			C461.19,284.1,458.87,286.42,456,286.42z"
        />
      </g>
      <g>
        <path
          d="M401.68,236.93H73.93c-2.87,0-5.19-2.32-5.19-5.19c0-2.87,2.32-5.19,5.19-5.19h327.74c2.87,0,5.19,2.32,5.19,5.19
			C406.87,234.6,404.54,236.93,401.68,236.93z"
        />
      </g>
      <g>
        <path
          d="M307.93,403.76c-23.36,0-42.35-18.99-42.35-42.33c0-23.35,19-42.35,42.35-42.35c23.35,0,42.33,19,42.33,42.35
			C350.26,384.77,331.28,403.76,307.93,403.76z M307.93,329.46c-17.63,0-31.97,14.34-31.97,31.97c0,17.62,14.34,31.95,31.97,31.95
			c17.62,0,31.95-14.33,31.95-31.95C339.88,343.8,325.55,329.46,307.93,329.46z"
        />
      </g>
      <g>
        <path
          d="M573.35,403.76c-23.35,0-42.34-18.99-42.34-42.33c0-23.35,19-42.35,42.34-42.35c23.35,0,42.35,19,42.35,42.35
			C615.69,384.77,596.7,403.76,573.35,403.76z M573.35,329.46c-17.62,0-31.96,14.34-31.96,31.97c0,17.62,14.34,31.95,31.96,31.95
			c17.62,0,31.96-14.33,31.96-31.95C605.31,343.8,590.97,329.46,573.35,329.46z"
        />
      </g>
      <g>
        <path
          d="M630.58,366.62c-2.87,0-5.19-2.32-5.19-5.19s2.32-5.19,5.19-5.19h42.19c0.77-0.19,5.72-1.54,5.72-8.6v-61.77
			c0-13.17-5.5-22.05-5.55-22.15l-44.87-85.1c-0.17-0.32-0.31-0.67-0.41-1.03c-0.36-1.18-3.98-11.9-18.51-11.9h-88.93
			c-2.87,0-5.19-2.32-5.19-5.19c0-2.87,2.32-5.19,5.19-5.19h88.93c17.75,0,26.16,12.05,28.34,18.92l44.43,84.3
			c0.09,0.12,6.95,11.2,6.95,27.33v61.77c0,13.37-10.09,18.24-15.43,18.94L630.58,366.62z"
        />
      </g>
      <g>
        <path d="M251.52,361.43" />
      </g>
      <g>
        <path
          d="M471.22,366.62H363.92c-2.87,0-5.19-2.32-5.19-5.19s2.32-5.19,5.19-5.19h107.31c12.59,0,22.82-10.24,22.82-22.82V145.7
			c0-12.58-10.23-22.82-22.82-22.82c-2.87,0-5.19-2.32-5.19-5.19c0-2.87,2.32-5.19,5.19-5.19c18.31,0,33.21,14.89,33.21,33.21
			v187.72C504.43,351.72,489.53,366.62,471.22,366.62z"
        />
      </g>
      <g>
        <path
          d="M251.51,366.62h-24.27c-18.31,0-33.21-14.89-33.21-33.21v-13.73c0-2.87,2.32-5.19,5.19-5.19c2.87,0,5.19,2.32,5.19,5.19
			v13.73c0,12.58,10.23,22.82,22.82,22.82h24.27c2.87,0,5.19,2.32,5.19,5.19S254.38,366.62,251.51,366.62z"
        />
      </g>
      <g>
        <path
          d="M648.07,276.05h-86.24c-18.31,0-33.2-14.89-33.2-33.21v-22.22c0-18.3,14.89-33.2,33.2-33.2h49.51
			c2.87,0,5.19,2.32,5.19,5.19c0,2.87-2.32,5.19-5.19,5.19h-49.51c-12.58,0-22.81,10.23-22.81,22.81v22.22
			c0,12.58,10.23,22.82,22.81,22.82h86.24c2.87,0,5.19,2.32,5.19,5.19C653.26,273.72,650.94,276.05,648.07,276.05z"
        />
      </g>
    </g>
  </svg>
</template>
